<template>
	<div class="detail">
		<common-detail :detail="detail"></common-detail>
	</div>
</template>

<script>
import { detail } from '@/api/website/notice';
import CommonDetail from '@/components/website/CommonDetail.vue';
export default {
	name: 'PcWebsiteIntro',
	components:{CommonDetail},
	data() {
		return {
			detail: {}
		}
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		$route: {
			handler(v) {
				this.getdetail()
			}
		}
	},

	mounted() {
		this.getdetail()
	},

	methods: {

		handleCurrentChange(val) {
			this.page = val
			this.getdetail()
		},

		getdetail() {
			detail({ id: this.$route.params.id }).then(res => {

				this.detail = res.data

			})
		}
	},
};
</script>

<style lang="scss" scoped>


.detail {
	height: auto;
	width: 830px;
	padding: 0 20px;
	box-sizing: border-box;
}
</style>